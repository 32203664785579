import React from 'react'
import Typed from 'react-typed'
import '../App.css'

export default function Welcome() {
    return (
        <div>
            <div class="site-blocks-cover overlay" data-aos="fade" data-stellar-background-ratio="0.5">
      <div class="container">
        <div class="row align-items-center justify-content-center text-center">

          <div class="col-md-12" data-aos="fade-up" data-aos-delay="400">

            <div class="row justify-content-center mb-4">
              <div class="col-md-8 text-center">
                <h1> 
                  <span class="typed-words"> 
                  <Typed 
                  strings={['We Are Experts in Web Design &amp; Development',
                  'We Offer Reliable and Affordable Web Hosting', 
                  'We Design Elegant Graphics','We Are Experts in UI/UX Design']} 
                  typeSpeed={150} 
                  backSpeed={50} loop/> 
                  </span></h1>
                <p class="lead mb-5">We Craft Beautiful Web Apps</p>
                <div><a href="/contactus" class="btn btn-primary btn-md">Hire Us</a></div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <section class="section ft-feature-1">
      <div class="container">
        <div class="row align-items-stretch">
          <div class="col-12 bg-black w-100 ft-feature-1-content">
            <div class="row align-items-center">
              
              <div class="col-lg-5 ml-auto mr-auto">
                <div class="mb-5">
                  <h3 class="d-flex align-items-center"><span class="icon icon-phonelink mr-2"></span><span>Web Design &amp; Development</span></h3>
                  <p>We help you design, develop and implement your business idea into a web applications at an affordable price.</p>
                  <p><a href="/services">Read More</a></p>
                </div>

                <div>
                  <h3 class="d-flex align-items-center"><span class="icon icon-extension mr-2"></span><span>Web Hosting</span></h3>
                  <p>We host your ideas and works on a reliable hosting services at an affordable price.</p>
                  <p><a href="/services">Read More</a></p>
                </div>

              </div>
              <div class="col-lg-5 ml-auto mr-auto">
                <div class="mb-5">
                  <h3 class="d-flex align-items-center"><span class="icon icon-format_paint mr-2"></span><span>Graphic Design</span></h3>
                  <p>We help you give your business the uniqueness it deserves through our creativity and cutting edge graphic design skills.</p>
                  <p><a href="/services">Read More</a></p>
                </div>

                <div>
                  <h3 class="d-flex align-items-center"><span class="icon icon-question_answer mr-2"></span><span>UI/UX Design</span></h3>
                  <p>We create interface design that give your customers an exhilarating experience and the feel to get back again and again.</p>
                  <p><a href="/services">Read More</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
        </div>
    )
}
