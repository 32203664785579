import React from 'react'
import Navigation from '../Common/Navigation';

export default function ContactUs() {
    return (
        <div>
            <Navigation />
        </div>
    )
}
