import React from 'react'
import Navigation from '../Common/Navigation';
import IntroBanner from '../Common/IntroBanner';
import AboutDetail from '../Common/AboutDetail';
import HireUs from '../Common/HireUs'
import Values from '../Common/Values'

export default function About() {
    return (
        <div>
            <Navigation />
            <IntroBanner title="About Us"/>
            <AboutDetail />
            <Values />
            <HireUs />
        </div>
    )
}
