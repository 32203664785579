import React from 'react'
import '../App.css'

import InvestImage from '../Assets/Images/investNyeri.png'
import Ncop from '../Assets/Images/Ncop.png'
import Kamwana from '../Assets/Images/kamwana.png'
export default function Featured() {
    return (
        <div>
            <section class="site-section">
                <div className="container">
                <div class="row mb-5 justify-content-center">
                <div class="col-md-8 text-center">
                    <h2 class="text-black h1 site-section-heading text-center">Featured Projects</h2>
                    <p class="lead">Here are just but a handful of our past projects. We are commited to transforming your idea into a reality.</p>
                </div>
                </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <a href="http://investnyeri.herokuapp.com/" className="media-1">
                                <img src={InvestImage} alt="Rivoworld portfolio" className="img-fluid"/>
                                <div className="media-1-content">
                                    <h2>Nyeri Investment Portal</h2>
                                    <span className="category">Web Application</span>
                                </div>
                            </a>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <a href="http://ncop.herokuapp.com/" className="media-1">
                                <img src={Ncop} alt="Rivoworld portfolio" className="img-fluid"/>
                                <div className="media-1-content">
                                    <h2>Nyeri Cooperatives Reporting System</h2>
                                    <span className="category">Web Application</span>
                                </div>
                            </a>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <a href="https://kamwanasamwel.com/" className="media-1">
                                <img src={Kamwana} alt="Rivoworld portfolio" className="img-fluid"/>
                                <div className="media-1-content">
                                    <h2>Personal Profiles</h2>
                                    <span className="category">Mobile Application</span>
                                </div>
                            </a>
                        </div>

                        <div className="col-12 text-center mt-5">
                            <a href="/works" className="btn btn-primary btn-md">Show All Works</a>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    )
}
