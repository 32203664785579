import React from 'react'
import '../App.css'

export default function Values() {
    return (
        <div>
            <section class="site-section">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-4">
            <div class="p-3 box-with-humber">
              <div class="number-behind">01.</div>
              <h2>Our Values</h2>
              <p>Values we hold dearly are loyalty, reliability, authenticity and helping you soar higher. Our values remind us of our true north and allow us to see projects through to the very end.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="p-3 box-with-humber">
              <div class="number-behind">02.</div>
              <h2>Our Mission</h2>
              <p>To be the leading solutions provider to Africa and the world at large all through technology.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-4">
            <div class="p-3 box-with-humber">
              <div class="number-behind">03.</div>
              <h2>Our Philosophy</h2>
              <p>To all your dreams, technology makes it a reality. Rivoworld Solutions - Revolutionizing the World through Technology.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
        </div>
    )
}
