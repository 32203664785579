import './App.css';

import Home from './Components/Home';
import About from './Components/About';
import Work from './Components/Works';
import Services from './Components/Services';
import Contact from './Components/ContactUs';

import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path='/' exact component={Home}/>
          <Route path="/about" component={About}/>
          <Route path="/services" component={Services}/>
          <Route path="/works" component={Work}/>
          <Route path="/contactus" component={Contact}/>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
