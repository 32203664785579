import React from 'react';
import '../App.css'
const AboutDetail = () => {
    return (
        <div>
            <div className="row justify-content-center mt-4 pt-4 pb-4">
                <div className="col-md-8 text-left ">
                    <p><b>Welcome to Rivoworld Solutions. </b>
                        Are you are looking for High Quality Website Design and Development Services to increase your Business Presence Or Start Selling Your Product Online? You are Looking at right Gig. We create hand coded,cutting edge,User friendly Responsive websites. We will help you from start to finish.</p>
                    <p>We will Provide you:</p>
                    <ul>
                        <li>High Quality Code with fully Responsive Design</li>
                        <li>Mobile,Tablet and Desktop Friendly</li>
                        <li>Search Engine Friendly Markup and URL structure</li>
                        <li>Unlimited revisions till 100% satisfaction</li>
                        <li>Easy to use admin panel and User Manual to manage your website later.</li>
                        <li>Social media integration and Google Analytics Installation</li>
                        <li>Secure architecture with no security issues and bugs</li>
                        <li>Uploading and Configuring your final Website to your domain</li>
                        <li>3 months Free Bug support after Deployment</li>
                    </ul>
                    <p>We provide websites for Businesses,Education,Entertainment,Real estate,Medical,Law, Retail,Wedding,Saloon,Health & Fitness,Ecommerce Stores,Non-Profit Businesses.We are also ready for any custom Development needs about any Business.

                        </p><p><b>Please contact us today, Special Discounts for repeating Buyers!</b></p>
                </div>
            </div>
        </div>
    );
}

export default AboutDetail;
