import React from 'react'
import Navigation from '../Common/Navigation';
import IntroBanner from '../Common/IntroBanner';
import Portfolio from '../Common/Portfolio';

export default function Works() {
    return (
        <div>
            <Navigation />
            <IntroBanner title="Our Portfolio"/>
            <Portfolio />
        </div>
    )
}
