import React from 'react'
import '../App.css'
import logo from '../Assets/Images/logo.png'

import {FaFacebookF,  FaTwitter, FaInstagram, FaLinkedinIn} from 'react-icons/fa'

import {Navbar, Nav, NavDropdown, Col, Row, Image, Container} from 'react-bootstrap'

export default function Navigation() {
    return (
        <div>
            <div className="py-2">
                <div className="container">
                    <div className="row content-center">
                        <div className="col-md-12 col-12">
                        <p className="mb-0 p-3">
                            <span className="mr-3 mt-3"><strong>Phone:</strong> <a href="tel://+254702518467" className="body-font">+2547 0251 8467</a></span>
                            <span><strong>Email:</strong> <a href="info@rivoworld.com" className="body-font">info@rivoworld.com</a></span>
                        </p>
                        </div>

                        {/* <div className="col-md-6 col-12">
                            <ul className="social-icons">
                                <li><a href="#" className="p-2"><FaFacebookF /></a></li>
                                <li><a href="#" className="p-2"><FaTwitter /></a></li>
                                <li><a href="#" className="p-2"><FaInstagram /></a></li>
                                <li><a href="#" className="p-2"><FaLinkedinIn /></a></li>
                            </ul>
                        </div> */}
                    </div>
                </div>

            </div>
            <Navbar bg="light" expand="lg">
                <Image src={logo} roundedCircle height="70px" width="70px" className="ml-2 mr-4"/>
            
                      <Navbar.Brand href="/" className="text-black h2 mb-0  ml-auto" ><h2><strong>Rivo<span className="second-color">World</span></strong> </h2></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="ml-5 justify-content-end">
                        <Nav className="mr-auto navend">

                        <Nav.Link href="/" className="mr-4 ml-4 text-black">Home</Nav.Link>
                        <Nav.Link href="/about" className="mr-4 ml-4 text-black">About</Nav.Link>
                        <NavDropdown title="Services" id="basic-nav-dropdown" className="mr-4 ml-4 color-black">
                            <NavDropdown.Item href="/services" className="text-black">Web Design</NavDropdown.Item>
                            <NavDropdown.Item href="/services" className="text-black">Graphic Design</NavDropdown.Item>
                            
                        </NavDropdown>
                        <Nav.Link href="/works" className="mr-4 ml-4 text-black">Portfolio</Nav.Link>
                        <Nav.Link href="/contactus" className="mr-4 ml-4 text-black">Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                  
                
               
                
            </Navbar>

        </div>
    )
}
