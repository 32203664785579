import React from 'react'
import '../App.css'

import InvestImage from '../Assets/Images/investNyeri.png'
import Ncop from '../Assets/Images/Ncop.png'
import Kamwana from '../Assets/Images/kamwana.png'
export default function Portfolio() {
    return (
        <div>
            <section class="site-section">

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <a href="http://investnyeri.herokuapp.com/" className="media-1">
                                <img src={InvestImage} alt="Rivoworld portfolio" className="img-fluid"/>
                                <div className="media-1-content">
                                    <h2>Nyeri Investment Portal</h2>
                                    <span className="category">Web Application</span>
                                </div>
                            </a>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <a href="http://ncop.herokuapp.com/" className="media-1">
                                <img src={Ncop} alt="Rivoworld portfolio" className="img-fluid"/>
                                <div className="media-1-content">
                                    <h2>Nyeri Cooperatives Reporting System</h2>
                                    <span className="category">Web Application</span>
                                </div>
                            </a>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <a href="https://kamwanasamwel.com/" className="media-1">
                                <img src={Kamwana} alt="Rivoworld portfolio" className="img-fluid"/>
                                <div className="media-1-content">
                                    <h2>Personal Profiles</h2>
                                    <span className="category">Mobile Application</span>
                                </div>
                            </a>
                        </div>

                        
                    </div>
                </div>

            </section>
        </div>
    )
}
