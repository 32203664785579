import React from 'react';
import '../App.css'

import Background from '../Assets/Images/background.jpg'

var backgroundstyle = {
    backgroundImage: `url(${Background})`
}

const IntroBanner = (props) => {
    return (
        <div>
            <div className="site-blocks-cover inner-page-cover overlay" style={ backgroundstyle } data-aos="fade"  data-stellar-background-ratio="0.5">
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center">
                        <div className="col-md-12" data-aos="fade-up" data-aos-delay="400">

                            <div className="row justify-content-center mb-4">
                                <div className="col-md-8 text-center">
                                    <h1>{props.title} </h1>
                                    <p className="lead mb-5">We Craft Beautiful Web Apps</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IntroBanner;
