import React from 'react'
import Navigation from '../Common/Navigation';
import Welcome from '../Common/Welcome'
import Values from '../Common/Values'
import Featured from '../Common/Featured'
import HireUs from '../Common/HireUs'
export default function Home() {
    return (
        <div>
            <Navigation />
            <Welcome />
            <Values />
            <Featured />
            <HireUs />
        </div>
    )
}
