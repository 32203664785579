import React from 'react'
import '../App.css'

export default function HireUs() {
    return (
        <div>
            <a href="/contactus" class="bg-primary py-5 d-block">
                <div class="container">
                    <div class="row justify-content-center hire-me">
                        <div class="col-md10">
                            <h2 class="text-white">Hire Us &amp; Let's Get Started</h2>
                        </div>
                    </div>
                </div>  
            </a>
        </div>
    )
}
